<template>
  <Layout>
    <div class="app-pin-edit">
      <a-page-header
        title="拼团"
        sub-title="自动创建等额隐藏优惠券，用户拼团成功，领取优惠券"
      />
      <a-form-model :model="form" ref="form">
        <div class="adv-form">
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-model-item
                required
                label="活动开始时间"
                prop="startTime"
                :rules="{
                  required: true,
                  message: '开始时间必填'
                }"
              >
                <a-date-picker
                  v-model="form.startTime"
                  valueFormat="YYYY-MM-DD HH:mm"
                  format="YYYY-MM-DD HH:mm"
                  :disabled-date="disabledStartDate"
                  :show-time="{ format: 'h:mm' }"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item
                required
                label="活动结束时间"
                prop="endTime"
                :rules="{
                  required: true,
                  message: '结束时间必填'
                }"
              >
                <a-date-picker
                  v-model="form.endTime"
                  valueFormat="YYYY-MM-DD HH:mm"
                  format="YYYY-MM-DD HH:mm"
                  :disabled-date="disabledEndDate"
                  :show-time="{ format: 'h:mm' }"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item
                required
                label="成团人数"
                prop="num"
                :rules="[
                  {
                    required: true,
                    message: '成团人数必填'
                  }
                ]"
              >
                <a-input-number v-model="form.num" :min="2" :max="10000" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item
                required
                label="成团有效时间（小时）"
                prop="expireHour"
                :rules="[
                  {
                    required: true,
                    message: '有效时间必填'
                  }
                ]"
              >
                <a-input-number v-model="form.expireHour" :min="1" :max="100" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-model-item
                required
                label="分享标题"
                prop="shareTitle"
                :rules="[
                  {
                    required: true,
                    message: '分享标题必填'
                  }
                ]"
              >
                <span slot="help"
                  >动态替换：
                  <a-tag color="pink">%标题%</a-tag>
                  <a-tag color="pink">%价格%</a-tag>
                  <a-tag color="pink">%昵称%</a-tag>
                </span>
                <a-input v-model="form.shareTitle" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                help="建议长宽比：5:4，例如：600 x 480"
                prop="shareImg"
              >
                <span slot="label">
                  分享封面&nbsp;
                  <a-tooltip title="不设置封面时默认使用屏幕截图">
                    <a-icon type="question-circle-o" />
                  </a-tooltip>
                </span>
                <a-upload
                  :data="{ index: 0 }"
                  :action="UPLOAD_URL"
                  :headers="{ t: getToken }"
                  withCredentials
                  accept="image/*"
                  :showUploadList="false"
                  @change="handleUpload"
                  :before-upload="beforeUpload"
                >
                  <img
                    v-if="form.shareImg"
                    class="upload-image-small"
                    :src="form.shareImg"
                  />
                  <a-button-group>
                    <a-button>
                      <a-icon type="upload" />
                      {{ form.shareImg ? "更换" : "上传" }}图片
                    </a-button>
                    <a-button
                      @click.stop="deleteImg"
                      v-if="form.shareImg"
                      type="danger"
                    >
                      <a-icon type="delete" /> 删除
                    </a-button>
                  </a-button-group>
                </a-upload>
              </a-form-item>
            </a-col>
          </a-row>
        </div>

        <div v-if="mode === 'add'" class="tool">
          <a-button @click="openProductDialog">
            <a-icon type="plus" /> 添加商品
          </a-button>
        </div>

        <a-table
          class="table-wrap"
          :columns="columns"
          :data-source="form.rows"
          :pagination="false"
          rowKey="productId"
        >
          <span slot="img" slot-scope="img">
            <img :src="img" class="product-img" />
          </span>
          <span slot="pinPrice" slot-scope="pinPrice, record, index">
            <a-input-number
              :value="pinPrice"
              @change="val => changePinPrice(val, index)"
              :min="0.01"
              :max="record.price"
              :step="0.1"
            />
          </span>
          <span slot="action" slot-scope="_, record, index">
            <a-button v-if="mode === 'add'" type="link" @click="delItem(index)">
              删除
            </a-button>
          </span>
        </a-table>
        <div style="height:100px" />

        <div class="footer">
          <a-button type="primary" html-type="submit" @click="submitForm">
            保存
          </a-button>
          <a-button style="margin-left: 10px" @click="cancel">
            取消
          </a-button>
        </div>
      </a-form-model>
    </div>

    <!-- 商品选择器 -->
    <ProductDialog
      id="productDialog"
      ref="productDialog"
      v-on:selected="addItem"
      :multiple="true"
      only-self-product
    />
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

const moment = require("moment");

const PinSvc = require("@/service/pin");

import loadDataMixin from "@/mixins/loadData";
import productMixin from "@/mixins/product";
import textMixin from "@/mixins/text";
import uploadMixin from "@/mixins/upload";

const columns = [
  {
    title: "图片",
    align: "center",
    dataIndex: "img",
    key: "img",
    width: 60,
    scopedSlots: { customRender: "img" }
  },
  {
    title: "标题",
    dataIndex: "title",
    key: "title"
  },
  {
    title: "原价",
    align: "right",
    dataIndex: "price",
    key: "price",
    width: 100
  },
  {
    title: "拼团价",
    align: "center",
    dataIndex: "pinPrice",
    key: "pinPrice",
    width: 100,
    scopedSlots: { customRender: "pinPrice" }
  },
  {
    title: "操作",
    align: "center",
    key: "action",
    width: 80,
    scopedSlots: { customRender: "action" }
  }
];

export default {
  name: "PinEdit",

  data() {
    return {
      mode: "add", // 模式：add 批量新增，edit 单个编辑

      form: {
        startTime: null,
        endTime: null,
        num: 2,
        expireHour: 1,

        shareTitle: "%昵称%,帮我点一下,就能和我一起领惊喜",
        shareImg: "",

        rows: []
      }
    };
  },

  computed: {
    columns() {
      return columns;
    }
  },

  mixins: [loadDataMixin, productMixin, textMixin, uploadMixin],

  methods: {
    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { url } = data;
        this.form.shareImg = url;
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    },
    deleteImg() {
      this.form.shareImg = "";
    },

    openProductDialog() {
      this.$refs.productDialog.show();
    },

    addItem(vals) {
      // 去重
      for (const val of vals) {
        if (this.form.rows.findIndex(x => x.id === val.id) > -1) {
          continue;
        }

        const { id, title, img, price } = val;
        const pinPrice = price;
        this.form.rows.push({ id, title, img, price, pinPrice });
      }
    },
    delItem(index) {
      this.form.rows.splice(index, 1);
    },

    // 活动日期
    disabledStartDate(startValue) {
      const endValue = this.form.endTime;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > moment(endValue).valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.form.startTime;
      if (!endValue || !startValue) {
        return false;
      }
      return moment(startValue).valueOf() >= endValue.valueOf();
    },

    changePinPrice(val, index) {
      this.form.rows[index].pinPrice = val;
    },

    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          let {
            id,
            startTime,
            endTime,
            num,
            expireHour,
            shareTitle,
            shareImg,
            rows
          } = this.form;
          if (rows.length <= 0) {
            this.$message.error("请至少添加一个商品拼团价");
            return;
          }

          const line = JSON.stringify(
            rows.map(x => ({
              productId: x.id,
              title: x.title,
              img: x.img,
              price: x.price,
              pinPrice: x.pinPrice
            }))
          );
          try {
            let val = {
              startTime,
              endTime,
              num,
              expireHour,

              shareTitle,
              shareImg,

              line
            };

            if (this.mode === "edit") {
              val.id = id;
            }
            await PinSvc.createOrUpdate(val);

            const msg = this.mode === "add" ? "创建成功" : "保存成功";
            this.$message.info(msg);
            this.$router.replace({ path: "/sale/pin" });
          } catch (err) {
            console.error(err);
            this.$message.error("创建活动失败，错误：" + err.message);
          }
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    },
    cancel() {
      this.$router.replace({ path: "/sale/pin" });
    }
  },

  async mounted() {
    let { id } = this.$route.query || {};
    this.mode = id ? "edit" : "add";

    if (id) {
      try {
        id = parseInt(id);
        let res = await PinSvc.detail(id);
        let {
          startTime,
          endTime,
          num,
          expireHour,

          shareTitle,
          shareImg,

          productId,
          title,
          img,
          price,
          pinPrice
        } = res;

        // 默认分享标题
        if (!shareTitle) {
          shareTitle = "%昵称%,帮我点一下,就能和我一起领惊喜";
        }

        const rows = [{ id: productId, title, img, price, pinPrice }];
        this.form = {
          id,
          startTime,
          endTime,
          num,
          expireHour,

          shareTitle,
          shareImg,

          rows
        };
      } catch (err) {
        console.error(err);
        this.$message.error("加载活动失败，错误：" + err.message);
      }
    }
  },

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-pin-edit {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .adv-form {
    padding: 14px;
    margin-bottom: 10px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;

    table {
      width: 100%;

      img {
        width: 100px;
        max-height: 100px;
      }
    }
  }

  .tool {
    padding: 24px 24px;
    background: #fff;
    display: flex;
    justify-content: space-between;
  }

  .upload-image-small {
    width: 40px;
    height: 32px;
    margin-right: 10px;
  }

  .table-wrap {
    background: #fff;
  }

  .product-img {
    width: 60px;
    max-height: 60px;
  }

  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: #fff;
    padding: 10px;
    text-align: center;
  }
}
</style>
